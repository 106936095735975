import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import MainLayout from '../../components/MainLayout'
import Button from '../../components/basic/Button'

import api from '../../backend'

import styles from './suche.module.scss'
import { TallyIcon } from '../../components/MaturityTallies'

import auth from '../../utils/auth'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      textAlign: 'left'
    },
    jlpt: {
      textAlign: 'right'
    },
    tally: {
      marginLeft: '4px'
    }
  })
)

interface IDataItem {
  qid: number
  word: string
  word_de: string
  reading: string
  list: string
  maturity?: number
  lastseen?: string
}

const SearchPage = () => {
  if (!auth.isAuthenticated()) {
    auth.login()
    return <p>Leite zum Login weiter...</p>
  }

  const classes = useStyles()

  const [checked, setChecked] = useState([])
  const handleToggle = (item: IDataItem) => () => {
    const currentIndex = checked.indexOf(item.qid)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(item.qid)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const [searchResult, setSearchResult] = useState<IDataItem[]>([]);
  const onSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    api.search(searchTerm).then((data) => {
      const combined = [...(data.words||[]), ...(data.kanjis||[])]
      setSearchResult(combined)
      setChecked([] /*combined.map((e: any) => e.qid)*/)
    })
  }

  const [searchTerm, setSearchTerm] = useState<string>('');
  const onSearchFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const onAddWords = () => {
    const wordsToStart = { qids: checked.map(e => ({ qid: e })) }

    api.startReading(wordsToStart).then((data) => {
      api.search(searchTerm).then((response) => {
        const combined = [...(response.words||[]), ...(response.kanjis||[])]
        setSearchResult(combined)
        setChecked([] /*combined.map((e: any) => e.qid)*/)
      })
    })
  }

  return (
    <MainLayout>
      <div className={styles.container}>
        <form onSubmit={onSearch} autoComplete="false" autoCorrect="false" autoCapitalize="false" spellCheck="false">
          <p>Füge deinem Lernstapel einzelne Vokabeln hinzu.</p>
          <p>Hinzugefügt tauchen sie dann in der Stufe <TallyIcon level={0}/> auf. Lernen kannst du sie ganz normal mit 'Wiederholen'.</p>
          <p>Hinweis: Manche Lesungen fehlen im Datensatz noch. Es kann vorkommen, dass du eine seltene N1 Lesung findest, aber nicht häufigere N5-N2 Lesung.
            Bsp: 一人 wird meistens ひとり gelesen, いちにん kommt aber in besonderen Fällen auch vor.</p>
          <input className={styles.searchField} name="seachTerm" type="text" placeholder="Kanji oder mit Komma getrennte Vokabeln"
            autoComplete="false" autoCorrect="false" autoCapitalize="false" spellCheck="false"
            value={searchTerm}
            onChange={onSearchFieldChange} />
          <div>
            <Button type='submit' className={styles.actionSearch}>Suchen</Button>
          </div>
          <div>
            {searchResult && searchResult.length > 0 && 
              <>
                <Button onClick={onAddWords} className={styles.actionSearch} buttonStyle='secondary' disabled={checked.length===0}>Auswahl zum Lernen markieren</Button>
                <p>
                  Das Ergebnis enthält auch Vokabeln, die du bereits lernst. Du erkennst sie an der Vokabelstufe rechts (z.B. <TallyIcon level={1}/>).<br/>
                  Zuerst kommen Vokabeln, die du genau deiner Eingabe entsprechen. Danach kommen Vokabeln, die einzelne Kanji aus deiner Suche enthalten.
                </p>
              </>
            }
          </div>
          <List className={classes.root}>
            {searchResult && searchResult.map((el) => {
              const labelId = `checkbox-list-label-${el.qid}`
              return (
                <ListItem key={el.qid} role={undefined} dense button onClick={handleToggle(el)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(el.qid) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${el.word}（${el.reading}） ${el.word_de||''}`} />
                  <ListItemText primary={(el.list||'').toUpperCase()} className={classes.jlpt} />
                  <ListItemIcon className={classes.tally}>
                    <TallyIcon level={el.maturity} />
                  </ListItemIcon>
                </ListItem>
              )
            })}
          </List>
        </form>
      </div>
    </MainLayout>
  )
}

export default SearchPage
